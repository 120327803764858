import { MantineThemeOverride } from '@mantine/core';
import { open_sans, raleway } from './utils/fonts';

const orange = '#F25D1C';
export const theme: MantineThemeOverride = {
  fontFamily: open_sans,
  headings: {
    fontFamily: raleway,
    sizes: {
      h2: {
        fontSize: 24,
        lineHeight: '28px',
      },
      h3: {
        fontSize: 16,
        lineHeight: '19px',
        fontWeight: 600,
      },
      h4: {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '19px',
      },
      h5: {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: '16px',
      },
    },
  },
  fontSizes: {
    xs: 12,
    sm: 14,
    md: 16,
    lg: 18,
    xl: 24,
  },
  colors: {
    orange: [
      '#FDEAE1',
      '#FBCCB8',
      '#F9B293',
      '#F79971',
      '#F58352',
      '#F36F36',
      '#F25D1C',
      '#E64F0D',
      '#CF470C',
      '#BA400B',
    ],
    green: [
      '#CAE4C7',
      '#CAE4C7',
      '#CAE4C7',
      '#CAE4C7',
      '#CAE4C7',
      '#088529',
      '#088529',
      '#088529',
      '#088529',
      '#088529',
    ],
    gray: [
      '#F5F5F5',
      '#F5F5F5',
      '#F5F5F5',
      '#F5F5F5',
      '#D9D9D9',
      '#40404B',
      '#40404B',
      '#40404B',
      '#40404B',
      '#40404B',
    ],
  },
  primaryColor: 'orange',
  black: '#40404B',
  defaultRadius: 15,
  components: {
    AppShell: {
      defaultProps: {
        padding: 0,
        fixed: false,
      },
      styles: {
        root: {
          backgroundColor: '#F5F5F5',
        },
        main: {
          '> .mantine-Container-root': {
            padding: 40,
            maxWidth: 1116,
          },
        },
      },
    },
    Header: {
      defaultProps: {
        withBorder: false,
      },
      styles: {
        root: {
          padding: '39px 44px 20px 61px',
        },
      },
    },
    Grid: {
      defaultProps: {
        gutter: 20,
      },
      styles: {
        col: {
          '> .mantine-Container-root': {
            backgroundColor: 'white',
            borderRadius: 15,
            padding: '15px 20px',
            position: 'relative',
            minWidth: 332,
          },
        },
      },
    },
    Container: {
      defaultProps: {
        fluid: true,
      },
    },
    Title: {
      defaultProps: {
        order: 2,
      },
      styles: {
        root: {
          '&:is(h2)': {
            position: 'relative',
            paddingLeft: 24,
            marginBottom: 20,
            '&:before': {
              content: '""',
              position: 'absolute',
              left: 0,
              width: 12,
              height: 28,
              background: '#40404b',
              borderRadius: 3,
              top: '50%',
              transform: 'translateY(-50%)',
            },
          },
          '&:is(h5)': {
            fontFamily: open_sans,
          },
        },
      },
    },
    InputWrapper: {
      styles: {
        label: {
          fontWeight: 600,
          marginBottom: 5,
        },
        description: {
          color: '#AFAFBB',
        },
      },
    },
    Input: {
      styles: {
        input: {
          borderRadius: 10,
          boderColor: '#CED4DA',
          '&::placeholder': {
            color: '#AFAFBB',
          },
        },
      },
    },
    RadioGroup: {
      styles: {
        root: {
          '.mantine-Group-root': {
            paddingTop: 0,
          },
        },
      },
    },
    Checkbox: {
      styles: {
        input: {
          borderRadius: 4,
        },
      },
    },
    RichTextEditor: {
      styles: {
        toolbar: {
          padding: 7,
        },
        control: {
          width: 35,
          height: 35,
          border: 'none',
          borderRadius: '0 !important',
        },
        content: {
          padding: '10px 12px',
          fontSize: 14,
        },
      },
    },
    Dropzone: {
      styles: {
        root: {
          border: 'solid 1px #CED4DA',
        },
      },
    },
    Navbar: {
      defaultProps: {
        width: { base: 290 },
      },
      styles: {
        root: {
          minHeight: '100vh',
          minWidth: 290,
          backgroundColor: orange,
          padding: '45px 32px 90px 32px',
        },
      },
    },
    NavLink: {
      defaultProps: {
        color: 'orange',
      },
      styles: {
        root: {
          fontWeight: 600,
          borderRadius: 15,
          padding: '6px 12px',
          color: 'white',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
          },
          '&[data-active]': {
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            '& svg path': {
              fill: orange,
            },
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
            },
          },
        },
      },
    },
    Tabs: {
      styles: {
        panel: {
          paddingTop: 24,
        },
      },
    },
    Table: {
      defaultProps: {
        fontSize: 12,
      },
      styles: {
        root: {
          marginBottom: 15,
          thead: {
            tr: {
              th: {
                fontWeight: 600,
                lineHeight: '16px',
                color: '#40404b',
              },
              button: {
                padding: '7px 10px',
              },
            },
          },
        },
      },
    },
    Modal: {
      defaultProps: {
        zIndex: 401,
        // zIndex: 1000,
        size: 530,
      },
      styles: {
        title: {
          fontWeight: 700,
          fontSize: 24,
          lineHeight: '28px',
        },
        close: {
          backgroundColor: '#40404B',
          color: 'white',
          width: 48,
          height: 48,
          borderRadius: 24,
          position: 'absolute',
          top: -24,
          right: -24,
          zIndex: 2000,
          '&:focus': {
            outlineOffset: 0,
          },
          '&:hover': {
            backgroundColor: '#2c2c34',
          },
        },
      },
    },
    Avatar: {
      defaultProps: {
        size: 30,
      },
    },
    Stepper: {
      styles: {
        stepLabel: {
          fontSize: 12,
          lineHeight: '16px',
        },
        stepIcon: {
          fontSize: 0,
          width: 10,
          height: 10,
          minWidth: 10,
        },
        stepCompletedIcon: {
          display: 'none',
        },
      },
    },
    RingProgress: {
      defaultProps: {
        roundCaps: true,
      },
      styles: {
        root: {
          'circle:first-of-type': {
            stroke: 'rgba(250, 190, 164, 0.5)',
          },
        },
        label: {
          fontWeight: 600,
          fontSize: 16,
          lineHeight: '22px',
          textAlign: 'center',
        },
      },
    },
    Badge: {
      styles: {
        leftSection: {
          display: 'flex',
          marginRight: 'auto',
        },
        inner: {
          marginRight: 'auto',
          width: '100%',
          textAlign: 'center',
        },
      },
    },
  },
};
