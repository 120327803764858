import React, { useState } from 'react';

import { createBrowserSupabaseClient } from '@supabase/auth-helpers-nextjs';
import { SessionContextProvider } from '@supabase/auth-helpers-react';

import { AppProps } from 'next/app';
import Head from 'next/head';
import { MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import { RouterTransition } from '../components/shared/page/RouterTransition';
import { theme } from '../theme';
import '../styles/global.css';

export default function App({ Component, pageProps }: AppProps) {
  const [supabaseClient] = useState(() => createBrowserSupabaseClient());
  return (
    <>
      <Head>
        <title>AiRD</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>
      <SessionContextProvider
        supabaseClient={supabaseClient}
        initialSession={pageProps.initialSession}
      >
        <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
          <NotificationsProvider zIndex={402} autoClose={5000}>
            <RouterTransition />

            <Component {...pageProps} />
          </NotificationsProvider>
        </MantineProvider>
      </SessionContextProvider>
    </>
  );
}
